import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark
    let featuredImgFluid = frontmatter.featuredImage.childImageSharp.gatsbyImageData
    return (
      <>
      
    <div className="banner">
      
      <GatsbyImage image={featuredImgFluid} title={frontmatter.title} className="h-96 mt-20 object-cover" />
    </div>
      <Layout>
      <Seo title={frontmatter.title} description={excerpt} />
        <h1>{frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
      </>
    )
  }
  
  export const pageQuery = graphql`
    query($id: String!) {
      markdownRemark(id: { eq: $id }) {
        html
        excerpt
        frontmatter {
          slug
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `